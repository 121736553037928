import React, { useState, useEffect, useRef } from "react"
import { onValue, ref, set, remove, push } from "firebase/database"
import Toggle from "../components/Toggle"
import Moment from "react-moment"
import moment from "moment"
import LogoWhite from "../images/logoWhite.svg"
import noImage from "../images/no_image_available.png"
import { useQueryParam, StringParam } from "use-query-params"
import Cat from "../images/cat.svg"
import { db } from "../firebase"

export default function ManagePickup() {
  const [braceletID, setBraceleteId] = useState("")
  const [phone, setPhone] = useState("")
  const [queue, setQueue] = useState([])
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [disableInput, setDisableInput] = useState(
    queue.length > 3 ? true : false
  )
  const [condensed, setCondensed] = useState(false)
  const [show, setShow] = useState(false)
  const [arrival, setArrival] = useState(false)
  const [collapseAdd, setCollapseAdd] = useState(false)
  const [collapseManage, setCollapseManage] = useState(false)
  const [collapseAttendance, setCollapseAttendance] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [isDancersLoading, setIsDancersLoading] = useState(true)
  const [showNumber, setShowNumber] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [filteredSearchResults, setFilteredSearchResults] = useState([])

  const [allDancers, setAllDancers] = useState([])
  // const [id, setId] = useQueryParam("id", StringParam)
  const inputRef = useRef(null)

  useEffect(() => {
    if (typeof window !== "undefined") {
      setShow(localStorage.getItem("show") !== null)
      setArrival(localStorage.getItem("arrival") !== null)
      setShowNumber(localStorage.getItem("showNumber") !== null)
    }
  }, [])

  useEffect(() => {
    setIsDancersLoading(true)
    getAllDancers(localStorage.getItem("showNumber") !== null)
  }, [showNumber])

  useEffect(() => {
    if (searchTerm !== "") {
      setFilteredSearchResults(
        allDancers.filter(
          dancer =>
            dancer.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        )
      )
    } else {
      console.log("else")
      console.log(allDancers)
      setFilteredSearchResults(allDancers)
    }
  }, [searchTerm])

  const [queueNumber, setQueueNumber] = useState(
    typeof window !== "undefined"
      ? localStorage.getItem("queueAmount") || 10
      : 10
  )

  const sortByTimeAdded = arr => {
    arr.sort(function (a, b) {
      var keyA = new Date(a.time),
        keyB = new Date(b.time)
      // Compare the 2 dates
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })

    return arr
  }

  const addToQueue = async (id, phone, show) => {
    if (id != "") {
      try {
        const res = await fetch(
          `/.netlify/functions/putDancersOnQueue?id=${id}&phone=${phone}&show=${show}&arrival=${arrival}`
        )

        const data = await res.json()

        if (data.onQueue.length) {
          data.onQueue.forEach(dancer => {
            set(ref(db, dancer.name), {
              name: dancer.name,
              url: dancer.picture,
              time: Date.now(),
              id: dancer.id,
              costumes: dancer.costumes,
              bagNumber: dancer.bagNumber,
              // ageGroup: dancer.ageGroup,
            })
          })

          setTimeout(() => {
            setBraceleteId("")
            setPhone("")
            inputRef.current.focus()
          }, 500)
        } else {
          setError(true)
          setErrorMessage("Bracelet ID Not found")
        }
      } catch (e) {
        setError(true)
        setErrorMessage(
          "Something went wrong, please make sure phone or tag ID are correct"
        )
      }
    }
  }

  const handleSubmit = async (e, seachByphone, show) => {
    e.preventDefault()
    setError(false)
    if (seachByphone) {
      addToQueue(phone, seachByphone, show)
    } else {
      addToQueue(braceletID, seachByphone, show)
    }
  }

  // useEffect(() => {
  //   if (id) {
  //     addToQueue(id)
  //   }
  // }, [])

  useEffect(() => {
    onValue(ref(db), snapshot => {
      const data = snapshot.val()
      if (data) {
        const arrOfData = Object.keys(data).map(item => data[item])
        setQueue(arrOfData)
        if (arrOfData.length >= queueNumber) {
          setDisableInput(true)
          setError(true)
          setErrorMessage("Please remove dancers from Queue to add more")
        } else {
          setDisableInput(false)
          setError(false)
          setErrorMessage("")
        }
      } else {
        setQueue([])
        setDisableInput(false)
        setError(false)
        setErrorMessage("")
      }
      setIsLoading(false)
    })
  }, [])

  const removeFromQueue = async (name, id) => {
    remove(ref(db, `/${name}`))
    if (arrival) {
      setSearchTerm("")
      const res = await fetch(`/.netlify/functions/updateSingleDancer?id=${id}`)
      getAllDancers(showNumber)
    }
  }

  const getAllDancers = async (showNumber = "false") => {
    try {
      const res = await fetch(
        `/.netlify/functions/getAllDancersAttendance?show=${showNumber}`
      )
      const data = await res.json()
      setAllDancers(data)
      setFilteredSearchResults(data)
      setIsDancersLoading(false)
    } catch (e) {
      setError(true)
      setErrorMessage(
        "Something went wrong, please make sure phone or tag ID are correct"
      )
    }
  }

  return (
    <div className="w-full bg-ascendance py-5 min-h-screen">
      <div className="max-w-lg md:container mx-4 md:mx-auto">
        <div className="bg-gray-200 rounded-md">
          <div
            className={`bg-white px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between ${
              collapseAdd ? "rounded-md" : "rounded-t-md"
            }`}
          >
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Add To Queue
            </h3>
            <button
              className={`${collapseAdd ? "" : "transform rotate-180"}`}
              onClick={() => setCollapseAdd(!collapseAdd)}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="1.5em"
                width="1.5em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                </g>
              </svg>
            </button>
          </div>
          <div
            className={`${
              collapseAdd
                ? "hidden"
                : "p-4 space-y-3 bg-white flex flex-col items-end justify-end"
            }`}
          >
            <Toggle
              setEnabled={value => {
                if (value) {
                  setShow(true)
                  localStorage.setItem("show", true)
                } else {
                  setShow(false)
                  localStorage.removeItem("show")
                }
              }}
              enabled={show}
              copy="Rehearsal"
              secondaryCopy={"Show"}
            />
            <Toggle
              setEnabled={value => {
                if (value) {
                  setArrival(true)
                  localStorage.setItem("arrival", true)
                } else {
                  setArrival(false)
                  localStorage.removeItem("arrival")
                }
              }}
              enabled={arrival}
              copy="Dismissal"
              secondaryCopy={"Arrival"}
            />
          </div>
          <div className={`p-5 ${collapseAdd ? "hidden" : "block"}`}>
            <form
              className={`flex-col md:flex-row md:space-x-5 md:items-end flex`}
            >
              <label>
                <p className="text-gray-700 text-sm pb-2">Bracelet ID</p>
                <input
                  className="shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full md:w-auto"
                  value={braceletID}
                  onChange={e => setBraceleteId(e.target.value)}
                  disabled={disableInput}
                  maxLength="10"
                  autoFocus
                  ref={inputRef}
                />
              </label>
              <button
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-3 justify-center"
                type="submit"
                onClick={e => handleSubmit(e, false, show)}
              >
                Submit to Queue
              </button>
            </form>
            <form
              className={`flex-col md:flex-row md:space-x-5 md:items-end flex mt-2`}
            >
              <label>
                <p className="text-gray-700 text-sm pb-2">Phone Number</p>
                <input
                  className="shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full md:w-auto"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  disabled={disableInput}
                  maxLength="10"
                  type="tel"
                />
              </label>
              <button
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-3 justify-center"
                type="submit"
                onClick={e => handleSubmit(e, true, show)}
              >
                Submit to Queue
              </button>
            </form>
            {error && <p className="text-red-500 mt-5">{errorMessage}</p>}
          </div>
        </div>

        <div className="bg-gray-200 mt-5 rounded-md">
          <div
            className={`bg-white px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between ${
              collapseManage ? "rounded-md" : "rounded-t-md"
            }`}
          >
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Manage Queue
            </h3>
            <button
              className={`${collapseManage ? "" : "transform rotate-180"}`}
              onClick={() => setCollapseManage(!collapseManage)}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="1.5em"
                width="1.5em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                </g>
              </svg>
            </button>
          </div>
          <div className={`${collapseManage ? "hidden" : "block"}`}>
            <div className="p-4 bg-white flex justify-end">
              <Toggle
                setEnabled={setCondensed}
                enabled={condensed}
                copy="Condensed"
              />
            </div>
            {queue.length > 0 && (
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 p-5">
                {sortByTimeAdded(queue).map(dancer => (
                  <div
                    className={`rounded-md p-5 bg-white shadow flex items-center  ${
                      condensed
                        ? "flex-row justify-between md:flex-col"
                        : "flex-col"
                    }`}
                    key={dancer.name}
                  >
                    {!condensed && (
                      <img
                        className="w-40 mx-auto rounded-full"
                        src={dancer.url === "" ? noImage : dancer.url}
                      />
                    )}
                    <div
                      className={`${
                        condensed ? "text-left md:text-center" : "text-center"
                      }`}
                    >
                      <h2
                        className={`text-xl text-center  ${
                          condensed ? "mt-0 md:mt-2" : "mt-2"
                        }`}
                      >
                        {dancer.name}
                      </h2>
                      <p>
                        <Moment format="h:mm A">{dancer.time}</Moment>
                      </p>
                    </div>
                    {/* <p className="border-2  border-ascendance rounded-md p-1 flex justify-center items-center mt-2 font-bold  text-lg text-gray-600">
                      {dancer.ageGroup}
                    </p> */}
                    <button
                      className={`inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  ${
                        condensed ? "mt-0 md:mt-3" : "mt-3"
                      }
                     `}
                      onClick={() => removeFromQueue(dancer.name, dancer.id)}
                    >
                      Remove
                    </button>
                    {dancer.bagNumber && (
                      <p className="border-2  border-ascendance rounded-lg flex justify-center items-center mt-2 p-1 font-bold text-center">
                        {dancer.bagNumber}
                      </p>
                    )}
                    {!dancer.bagNumber && (
                      <p className="border-2  border-ascendance rounded-lg flex justify-center items-center mt-2 p-1 font-bold text-center">
                        {dancer.costumes}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            )}
            {queue.length === 0 && !isLoading && (
              <div className="text-center my-5 pb-3 flex items-center justify-center flex-col">
                <img className="w-20" src={Cat} />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No dancers on queue
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Add dancers to the queue by scanning a wristband
                </p>
              </div>
            )}
            {isLoading && (
              <div className="flex justify-center items-center py-4">
                <p className="text-gray-500">Loading...</p>
              </div>
            )}
          </div>
        </div>

        <div className="bg-gray-200 mt-5 rounded-md">
          <div
            className={`bg-white px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between ${
              collapseAttendance ? "rounded-md" : "rounded-t-md"
            }`}
          >
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Attendance Report
            </h3>
            <button
              className={`${collapseAttendance ? "" : "transform rotate-180"}`}
              onClick={() => setCollapseAttendance(!collapseAttendance)}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="1.5em"
                width="1.5em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                </g>
              </svg>
            </button>
          </div>
          <div className={`${collapseAttendance ? "hidden" : "block"}`}>
            <div className="p-4 bg-white flex justify-end">
              <Toggle
                setEnabled={value => {
                  if (value) {
                    setShowNumber(true)
                    localStorage.setItem("showNumber", true)
                  } else {
                    setShowNumber(false)
                    localStorage.removeItem("showNumber")
                  }
                }}
                enabled={showNumber}
                copy="Show 1"
                secondaryCopy="Show 2"
              />
            </div>
            {/* <div className="p-4 bg-white flex justify-end"></div> */}
            {isDancersLoading && (
              <div className="flex justify-center items-center py-4">
                <p className="text-gray-500">Loading...</p>
              </div>
            )}
            {!isDancersLoading && (
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 px-2 md:px-10 ">
                <label>
                  <p className="text-gray-700 text-sm pb-2">Name Search</p>
                  <input
                    className="shadow appearance-none border rounded  py-2 px-3 mb-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full md:w-auto"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                </label>
                <table className="min-w-full divide-y divide-gray-300 bg-white rounded-lg">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Phone
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell"
                      >
                        Bag Number
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell"
                      >
                        Program
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell"
                      >
                        Show
                      </th>
                    </tr>
                  </thead>
                  {console.log(filteredSearchResults)}
                  <tbody className="divide-y divide-gray-200">
                    {filteredSearchResults.map((dancer, i) => (
                      <tr key={dancer.name}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                          {i + 1}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {dancer.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {dancer.phone}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden md:table-cell">
                          {dancer.bagNumber}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden md:table-cell">
                          {dancer.program}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden md:table-cell">
                          {dancer.show}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* <div className="grid grid-cols-3 gap-y-1 p-10">
              {allDancers.length > 0 &&
                allDancers.map(dancer => (
                  <div className="border">
                    {dancer.name} | {dancer.phone}
                  </div>
                ))}
            </div> */}

            {allDancers.length === 0 && !isDancersLoading && (
              <div className="text-center my-5 pb-3 flex items-center justify-center flex-col">
                <img className="w-20" src={Cat} />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No dancers on queue
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Add dancers to the queue by scanning a wristband
                </p>
              </div>
            )}
          </div>
        </div>

        {/* <div className="bg-white mt-10 p-5 rounded-md">
          <h2 className="text-5xl text-gray-600">Display Settings</h2>
          <div className="mt-4">
            <label>Number of Columns</label>
            <input
              className="shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ml-3"
              type="number"
            />
          </div>
        </div> */}
      </div>
      {/* <img
        className="md:w-48 lg:w-96 hidden md:block absolute bottom-10 right-10"
        src={LogoWhite}
        alt="Company Logo"
      /> */}
    </div>
  )
}
